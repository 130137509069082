import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "GameModuleMixin",
  computed: {
    ...mapState('gameModule', ["gameModuleLoaded", "gameModuleLoading", "gameModuleItems"]),
    ...mapState('authModule', ['token']),
    ...mapGetters('gameModule', ['pendingGames'])
  },
  methods: {
    ...mapActions('gameModule', ["fetchAllGames", "createGame", "updateGame", "approveGameChangeRequest", "denyGameChangeRequest", "fetchComments", "createComment", "updateComment"]),
    async fetchGames(force = false) {
      if (!this.gameModuleLoading && (force || !this.gameModuleLoaded))
        await this.fetchAllGames()
    }
  },
  async created() {
    this.fetchGames()
  },
  watch: {
    token() {
      this.fetchGames() // force needed if games become viewer-specific
    }
  }
}