import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

let analytics = null;

const firebaseInit = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAyp4G9675PJOGCuR6m7DR2BfcfdXuoPcM",
    authDomain: "mit-jatsszunk-online.firebaseapp.com",
    projectId: "mit-jatsszunk-online",
    storageBucket: "mit-jatsszunk-online.appspot.com",
    messagingSenderId: "6736166982",
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  };
  console.groupCollapsed("firebase")
  console.log(firebaseConfig)
  console.groupEnd()
  firebase.initializeApp(firebaseConfig);
  //if (process.env.NODE_ENV === 'production') 
  analytics = firebase.analytics();
}

const analyticsLogger = {
  logEvent(...p) {
    if (analytics)
      analytics.logEvent(...p)
  },
  setCurrentScreen(...p) {
    if (analytics)
      analytics.setCurrentScreen(...p)
  }
}

export { firebase, firebaseInit, analyticsLogger }