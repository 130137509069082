import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { analyticsLogger } from "../firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Mit játsszunk online?'
    }
  },
  {
    path: '/mi-ez',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'Mi ez? - Mit játsszunk online?',
    }
  },
  {
    path: '/jatekok',
    name: 'Games',
    component: () => import('../views/Games.vue'),
    meta: {
      title: 'Játékok - Mit játsszunk online?',
    }
  },
  {
    path: '/jatekok/uj-jatek',
    name: 'CreateGame',
    component: () => import('../views/GameEditor.vue'),
    meta: {
      title: 'Új játék - Mit játsszunk online?',
    }
  },
  // {
  //   path: '/gid/:gameId',
  //   name: 'ViewGameById',
  //   component: () => import('../views/GameView.vue'),
  //   meta: {
  //     title: 'Mit játsszunk online?',
  //   }
  // },
  {
    path: '/jatekok/:gamePath',
    name: 'ViewGame',
    component: () => import('../views/GameView.vue'),
    meta: {
      title: 'Mit játsszunk online?',
    }
  },
  {
    path: '/jatekok/:gamePath/szerkesztes',
    name: 'EditGame',
    component: () => import('../views/GameEditor.vue'),
    meta: {
      title: 'Játék szerkesztése - Mit játsszunk online?',
    }
  },
  {
    path: '/profilom',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      title: 'Profilom - Mit játsszunk online?',
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      title: 'Admin - Mit játsszunk online?',
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: '404 - Mit játsszunk online?',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    .forEach(tag => document.head.appendChild(tag));
  next();
})

router.afterEach(() => {
  //analyticsLogger.setCurrentScreen(to.fullPath)
  analyticsLogger.logEvent("screen_view");
})

export default router
