import axios from 'axios'
export default {
  namespaced: true,
  state: {
    userModuleItems: [],
    userModuleLoaded: false,
    userModuleLoading: false
  },
  getters: {
    currentUser: (state, getters, rootState) => {
      return state.userModuleItems.find(user => user.uid === rootState.authModule.uid)
    },
  },
  mutations: {
    setItems: (state, value) => state.userModuleItems = value,
    setLoaded: (state, value) => state.userModuleLoaded = value,
    setLoading: (state, value) => state.userModuleLoading = value
  },
  actions: {
    async fetchAllUsers({ commit }) {
      commit("setLoading", true)
      const result = await axios.get("/users")
      commit("setItems", result.data)
      commit("setLoaded", true)
      commit("setLoading", false)
    },
    async updateUser({ commit, state }, user) {
      commit("setLoading", true)
      const updated = (await axios.patch(`/users/${user.uid}`, user)).data.data
      const updatedItems = state.userModuleItems.map(u => u.uid === user.uid ? updated : u)
      commit("setItems", updatedItems)
      commit("setLoading", false)
    }
  }
}