<template>
  <div class="home">
    <b-container class="py-3">
      <b-jumbotron class="main-jumbo">
        <template #header>
          <span class="display-4 d-sm-none">
            Mit játsszunk
            <strong>.<span class="text-primary">online</span></strong
            >?
          </span>
          <span class="display-3 d-none d-sm-block">
            Mit játsszunk
            <strong>.<span class="text-primary">online</span></strong
            >?
          </span>
        </template>

        <template #lead>
          Nem találkozhattok élőben, de azért jó lenne közösen játszani
          valamivel?
        </template>

        <hr class="my-4" />

        <p class="mb-4">
          Ezen az egyszerű kis honlapon játékötleteket kaphattok és adhattok
          bármilyen létszám és korosztály számára!
        </p>

        <b-button variant="primary" :to="{ name: 'Games' }" class="mr-2 mb-2">
          Mutasd a játékokat!
        </b-button>
        <b-button variant="link" :to="{ name: 'About' }" class="mb-2">
          Mi ez?
        </b-button>
      </b-jumbotron>

      <page-title title="Reflektorfényben" titleTag="h2" />

      <b-card v-if="!gameModuleLoaded">
        <b-card-body>
          <b-overlay show no-wrap spinner-variant="primary" />
        </b-card-body>
      </b-card>

      <b-card-group columns>
        <game-card v-for="game in highlights" :key="game.id" :game="game" />
      </b-card-group>

      <b-jumbotron class="mt-3 py-4">
        <b-row>
          <b-col>
            <span class="h2 font-weight-light">
              Már
              <strong
                >.<span class="text-primary">
                  <number
                    ref="counter"
                    animationPaused
                    v-if="gameModuleLoaded"
                    :from="0"
                    :to="gameModuleItems.filter((g) => !!g.content).length"
                    :format="
                      (v) => {
                        return Math.round(v);
                      }
                    "
                    :duration="5"
                    :delay="0"
                    easing="Circ.easeInOut"
                    v-b-visible="startCounter"
                  />
                  <template v-else>..</template>
                </span></strong
              >
              játékunk van!
            </span>
          </b-col>
          <b-col cols="12" md="auto" class="d-flex mt-3 mt-md-0">
            <b-button class="ml-auto" variant="primary" :to="{ name: 'Games' }"
              >Tovább a játékokhoz</b-button
            >
          </b-col>
        </b-row>
      </b-jumbotron>

      <ad v-if="!headless" type="article" />

      <b-alert show variant="light" class="mt-3">
        <h4 class="alert-heading mt-1">Mit játsszunk <s>online</s>?</h4>
        <p>
          Ez az oldal nem egyenlő, se nem kapcsolatos a
          <strong>mitjatsszunkblog.hu</strong> oldallal. Szerzői jogi kérdések
          esetén bátran keressetek minket.
        </p>
        <hr />
        <p class="mb-0">
          Tévedésből vagy itt? Tovább a
          <a
            href="https://mitjatsszunkblog.hu/"
            target="_blank"
            class="card-link"
            >mitjatsszunkblog.hu</a
          >-ra
        </p>
      </b-alert>
    </b-container>
  </div>
</template>

<script>
import GameCard from "../components/GameCard.vue";
import PageTitle from "../components/PageTitle.vue";
import GameModuleMixin from "../mixins/GameModuleMixin";
import randomSample from "../utils/randomSample";
import Ad from "../components/Ad.vue";

export default {
  name: "Home",
  components: { PageTitle, GameCard, Ad },
  mixins: [GameModuleMixin],
  computed: {
    highlights() {
      const sorted = [...this.gameModuleItems].sort((a, b) => {
        if (!a.rating.average) return 1;
        if (!b.rating.average) return -1;
        return b.rating.average - a.rating.average;
      });
      const best10 = sorted.slice(0, 10);
      return randomSample(best10, 3);
    }
  },
  methods: {
    startCounter(visible) {
      if (visible) this.$refs.counter.play();
    }
  }
};
</script>
<style lang="scss" scoped>
.main-jumbo {
  padding-bottom: 2rem !important;
}
</style>
