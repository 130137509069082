import { mapState, mapGetters, mapActions } from "vuex";
import { firebase } from "../firebase"
export default {
  name: "AuthMixin",
  data: () => ({ waitingForPopup: false, requiredRole: null }),
  computed: {
    ...mapState('authModule', ['uid']),
    ...mapState({
      authLoaded: state => state.authModule.loaded
    }),
    ...mapGetters('userModule', ['currentUser'])
  },
  mounted() {
    this.checkIfGoHome()
  },
  methods: {
    ...mapActions({}),
    async loginWithGoogle() {
      try {
        this.waitingForPopup = true
        await firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider());
      } catch (error) {
        console.log(error);
      } finally {
        this.waitingForPopup = false
      }
    },
    async logout() {
      try {
        await firebase.auth().signOut();
        await this.$router.push({ name: "Home" });
      } catch (error) {
        console.log(error);
      }
    },
    async checkIfGoHome() {
      let redirect = false;
      if (this.requiredRole && this.authLoaded) {
        if (!this.uid) redirect = true;
        if (this.currentUser)
          redirect = !this.canView(this.currentUser.role, this.requiredRole);
      }
      if (redirect) {
        await this.$router.push({ name: "Home" });
        this.$bvToast.toast(`Nincs jogod megtekinteni ezt az oldalt. Jelentkezz be egy legalább ${this.$t(this.requiredRole)} szintű fiókkal, vagy kérj meg egy Admint, hogy adjon jogosultságot.`, {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
      }
    },
    canView(userRole, requiredRole) {
      console.log(userRole, requiredRole)
      const roles = ["user", "moderator", "admin"];
      const allow = roles.indexOf(userRole) >= roles.indexOf(requiredRole);
      if (!allow)
        console.warn("access denied for", userRole, "-", requiredRole, "required");
      return allow
    }
  },
  watch: {
    currentUser() {
      this.checkIfGoHome();
    },
    authLoaded() {
      this.checkIfGoHome();
    }
  }

}