<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <component :is="titleTag" class="pagetitle">
      <div class="d-block d-sm-none side-splash"></div>
      <span class="dot d-none d-sm-inline">.</span>{{ title }}
    </component>
    <div class="d-flex align-items-center">
      <slot name="action"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      default: "PageTitle"
    },
    titleTag: {
      type: String,
      default: "h2"
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.side-splash {
  position: absolute;
  left: 0;
  background-color: $primary;
  width: 0.2em;
  height: 1.2em;
  margin-top: 0;
  border-radius: 0 1em 1em 0;
}
.pagetitle {
  .dot {
    color: $primary !important;
    margin: 0 0.2rem;
  }
  // &::before {
  //   content: ".";
  //   margin: 0 0.2rem;
  //   color: $primary !important;
  // }
  font-weight: 700;
  margin: 0;
}
</style>
