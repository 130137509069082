import axios from 'axios'
const defaultTags = require("@/assets/defaultTags.json")

export default {
  namespaced: true,
  state: {
    gameModuleItems: [],
    gameModuleLoaded: false,
    gameModuleLoading: false
  },
  getters: {
    pendingGames: (state) => state.gameModuleItems.filter(
      (game) => !!game.changeRequests.find((cr) => !cr.moderatorId)
    ),
    gameTags: (state) => state.gameModuleItems.reduce(
      (acc, game) => {
        if (game.content && game.content.tags)
          game.content.tags.reduce((acc, tag) => {
            const existing = acc.find(t => t.text.toLowerCase() === tag.toLowerCase())
            if (existing) existing.count++;
            else acc.push({ text: tag, count: 1 })
            return acc
          }, acc)
        return acc
      }
      , defaultTags.map(text => ({ text, count: 0 }))).sort((a, b) => b.count - a.count)
  },
  mutations: {
    setItems: (state, value) => state.gameModuleItems = value.map(g => ({ ...g, changeRequests: g.changeRequests.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) })),
    setLoaded: (state, value) => state.gameModuleLoaded = value,
    setLoading: (state, value) => state.gameModuleLoading = value
  },
  actions: {
    async fetchAllGames({ commit }) {
      commit("setLoading", true)
      const result = await axios.get("/games")
      commit("setItems", result.data)
      commit("setLoaded", true)
      commit("setLoading", false)
    },
    async createGame({ commit, state }, data) {
      commit("setLoading", true)
      const result = (await axios.post("/games", data)).data
      commit("setItems", [...state.gameModuleItems, result])
      commit("setLoading", false)
      return result
    },
    async updateGame({ commit, state }, { gameId, data }) {
      commit("setLoading", true)
      const updated = (await axios.patch(`/games/${gameId}`, data)).data
      const updatedItems = state.gameModuleItems.map(g => g.id === updated.id ? updated : g)
      // console.log(updated, updatedItems)
      commit("setItems", updatedItems)
      commit("setLoading", false)
      return updated
    },
    async approveGameChangeRequest({ commit, state }, { gameId, crId }) {
      commit("setLoading", true)
      // console.log(gameId, crId)
      const updated = (await axios.patch(`/games/${gameId}/approve/${crId}`)).data
      const updatedItems = state.gameModuleItems.map(g => g.id === updated.id ? updated : g)
      // console.log(updated, updatedItems)
      commit("setItems", updatedItems)
      commit("setLoading", false)
      return updated
    },
    async denyGameChangeRequest({ commit, state }, { gameId, crId, moderatorComment }) {
      commit("setLoading", true)
      // console.log(gameId, crId)
      const updated = (await axios.patch(`/games/${gameId}/deny/${crId}`, { moderatorComment })).data
      const updatedItems = state.gameModuleItems.map(g => g.id === updated.id ? updated : g)
      // console.log(updated, updatedItems)
      commit("setItems", updatedItems)
      commit("setLoading", false)
    },
    async fetchComments({ commit, state }, { gameId }) {
      const comments = (await axios.get(`/games/${gameId}/comments`)).data
      comments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      // console.log("fetched", gameId, comments)
      const updatedItems = state.gameModuleItems.map(g => g.id === gameId ? { ...g, comments } : g)
      commit("setItems", updatedItems)
    },
    async createComment({ commit, state }, { gameId, data }) {
      const comment = (await axios.post(`/games/${gameId}/comments`, data)).data
      // console.log("commented", gameId, comment)
      comment; commit; state;
      //const updatedItems = state.gameModuleItems.map(g => g.id === gameId ? { ...g, comments } : g)
      //commit("setItems", updatedItems)
    },
    async updateComment({ commit, state }, { gameId, commentId, data }) {
      const comment = (await axios.patch(`/games/${gameId}/comments/${commentId}`, data)).data
      // console.log("edited", comment)
      comment; commit; state;
      //const updatedItems = state.gameModuleItems.map(g => g.id === gameId ? { ...g, comments } : g)
      //commit("setItems", updatedItems)
    },
  }
}