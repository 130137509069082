<template>
  <b-navbar
    toggleable="sm"
    type="light"
    variant="light"
    :class="{ 'shadow-sm': isScrolled || expanded }"
  >
    <b-navbar-brand to="/" class="d-flex align-items-center">
      <template v-if="prefix">
        <b-badge variant="primary" class="mr-1">{{ prefix }}</b-badge>
      </template>
      mitjátsszunk<strong class="online text-primary"
        >o<span class="d-none d-sm-inline">nline</span></strong
      >
    </b-navbar-brand>

    <b-navbar-toggle target="nav-text-collapse">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-compact-up"></b-icon>
        <b-icon v-else icon="chevron-compact-down"></b-icon>
      </template>
    </b-navbar-toggle>
    <b-collapse id="nav-text-collapse" is-nav v-model="expanded">
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ name: 'Games' }" :active="$route.name === 'Games'"
          >Játékok</b-nav-item
        >
        <b-nav-item :to="{ name: 'About' }" :active="$route.name === 'About'"
          >Mi ez?</b-nav-item
        >
        <b-nav-item
          v-if="
            currentUser &&
              (currentUser.role === 'admin' || currentUser.role === 'moderator')
          "
          :to="{ name: 'Admin' }"
          :active="$route.name === 'Admin'"
          >Admin</b-nav-item
        >
        <b-nav-item
          v-if="uid"
          :to="{ name: 'Profile' }"
          :active="$route.name === 'Profile'"
          >Profilom</b-nav-item
        >
        <b-button v-else variant="link" v-b-modal.sign-in-modal
          ><span class=" mr-1">Belépés</span>
          <b-icon icon="box-arrow-in-right"></b-icon>
        </b-button>
        <b-button
          variant="link"
          href="https://discord.gg/3d4jd6A4C3"
          target="_blank"
        >
          <b-icon icon="discord"></b-icon>
          <span class="d-sm-none"> Discord szerver</span>
        </b-button>
      </b-navbar-nav>
    </b-collapse>
    <b-modal id="sign-in-modal" title="Bejelentkezés" v-model="showSignInModal">
      <b-overlay :show="waitingForPopup" no-wrap spinner-variant="primary">
      </b-overlay>
      <b-button variant="outline-primary" @click="loginWithGoogle">
        <b-icon-google></b-icon-google>
        Google Bejelentkezés
      </b-button>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="showSignInModal = false"
        >
          Mégse
        </b-button>
      </template>
    </b-modal>
  </b-navbar>
</template>
<script>
import AuthMixin from "@/mixins/AuthMixin";
import UserModuleMixin from "@/mixins/UserModuleMixin";
export default {
  name: "NavBar",
  mixins: [AuthMixin, UserModuleMixin],
  data: () => ({ showSignInModal: false, isScrolled: false, expanded: null }),
  mounted() {
    window.addEventListener("scroll", () => {
      this.expanded = false;
      this.isScrolled = window.scrollY > 0;
    });
  },
  watch: {
    uid(uid) {
      if (uid) this.showSignInModal = false;
    },
    "$route.path": {
      handler() {
        this.expanded = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.online {
  &::before {
    content: ".";
    margin: 0 0.1rem 0 0.2rem;
    color: black !important;
  }
}

.navbar {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  .navbar-toggler {
    border: none;
  }
  .nav-link.active {
    &::before {
      content: ".";
      margin-right: 0.1rem;
      color: $primary !important;
      font-size: 1.2em;
      line-height: 1em;
    }
    font-weight: 700;
  }
}
</style>
