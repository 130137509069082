var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.game.content)?_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"pointer game-img",on:{"click":_vm.goToGame}},[_c('b-card-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.screenshotLoaded),expression:"screenshotLoaded"}],attrs:{"src":_vm.screenshot,"alt":((_vm.game.content.name) + " képernyőkép")},on:{"load":function($event){_vm.screenshotLoaded = true}}}),_c('vue-aspect-ratio',{directives:[{name:"show",rawName:"v-show",value:(!_vm.screenshotLoaded),expression:"!screenshotLoaded"}],staticClass:"placeholder",style:({
        'background-image': ("url(" + _vm.placeholder + ")"),
        overflow: 'hidden'
      }),attrs:{"ar":"1440:800"}})],1),_c('b-card-body',[_c('b-card-title',[_c('b-link',{staticClass:"card-link",attrs:{"to":{
          name: 'ViewGame',
          params: { gamePath: _vm.game.content.path }
        }}},[_c('strong',[_vm._v(" "+_vm._s(_vm.game.content.name)+" ")])])],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.game.content.lead)+" "),(_vm.game.content.tags)?_c('div',{staticClass:"mt-2"},[_vm._l((_vm.game.content.tags.slice(0, _vm.maxTagCount)),function(tag){return _c('b-tag',{key:tag,staticClass:"mb-1",attrs:{"no-remove":"","variant":"light"}},[_vm._v(" #"+_vm._s(tag)+" ")])}),(_vm.game.content.tags.length > _vm.maxTagCount)?_c('b-tag',{staticClass:"mb-1",attrs:{"no-remove":"","variant":"light"}},[_vm._v(" +"+_vm._s(_vm.game.content.tags.length - _vm.maxTagCount)+" ")]):_vm._e()],2):_vm._e(),(_vm.game.content.url)?_c('p',{staticClass:"my-1"},[_c('b-link',{staticClass:"card-link",attrs:{"target":"_blank","href":_vm.game.content.url}},[_vm._v(" "+_vm._s(_vm.displayUrl(_vm.game.content.url))+" ")])],1):_vm._e()])],1),_c('b-card-footer',[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.game.rating)?_c('star-rating',{attrs:{"value":_vm.game.rating.average,"readonly":""}}):_vm._e(),_c('b-link',{staticClass:"card-link ml-auto",attrs:{"to":{
          name: 'ViewGame',
          params: { gamePath: _vm.game.content.path }
        }}},[_vm._v(" Tovább ")])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }