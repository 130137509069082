import { mapState, mapGetters, mapActions } from "vuex";
// import { firebase } from "../firebase"
export default {
  name: "UserModuleMixin",
  computed: {
    ...mapState('userModule', ["userModuleLoaded", "userModuleLoading", "userModuleItems"]),
    ...mapState('authModule', ['token']),
    ...mapGetters('userModule', ["currentUser"])
  },
  methods: {
    ...mapActions('userModule', ["fetchAllUsers", "updateUser"]),
    async fetchUsers(force = false) {
      if (this.$store.state.authModule.loaded && !this.userModuleLoading && (force || !this.userModuleLoaded))
        await this.fetchAllUsers()
    }
  },
  async created() {
    this.fetchUsers()
  },
  watch: {
    token() {
      this.fetchUsers(true)
    }
  }
}