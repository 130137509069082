<template>
  <div class="d-flex">
    <div class="stars d-flex align-items-center" :class="{ inactive }">
      <div v-for="i in max" :key="i" class="star" :class="{ readonly }">
        <b-icon
          :icon="icon(i)"
          @click="readonly ? () => {} : $emit('input', i)"
        ></b-icon>
      </div>
      <div v-if="showValue && value" class="value">
        {{ formattedValue }}
      </div>
      <b-button
        variant="link"
        v-if="value && !readonly"
        size="sm"
        @click="$emit('input', null)"
      >
        <b-icon-x-circle />
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "StarRating",
  props: {
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 5
    },
    value: {
      type: Number || null,
      default: null
    },
    showValue: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    icon(i) {
      const rounded = Math.round(this.value * 2) / 2;
      if (i - rounded === 0.5) return "star-half";
      if (i <= rounded) return "star-fill";
      return "star";
    }
  },
  computed: {
    formattedValue() {
      return Intl.NumberFormat([], {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }).format(this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.stars {
  color: $primary;
  &.inactive {
    color: lightgray;
  }
}
.star {
  &:not(.readonly) {
    cursor: pointer;
  }
  & + .star {
    margin-left: 4px;
  }
}
.value {
  font-weight: 700;
  margin-top: 2px;
  margin-left: 8px;
}
</style>
