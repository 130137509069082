import { firebase } from '../firebase'
import jwt_decode from "jwt-decode"

export default {
  namespaced: true,
  state: {
    uid: null,
    token: null,
    loaded: false,
  },
  mutations: {
    setToken: (state, value) => state.token = value,
    setUid: (state, value) => state.uid = value,
    setLoaded: (state, value) => state.loaded = value
  },
  actions: {
    async login({ commit, state }, { u, authLoaded = false } = {}) {
      const user = u || firebase.auth().currentUser;
      try {
        if (!user)
          throw 'no-user'
        const decoded = jwt_decode(state.token);
        const now = new Date().getTime();
        if (Math.abs(new Date(decoded.exp * 1000).getTime() - now) < Math.abs(new Date(decoded.iat * 1000).getTime() - now))
          throw 'refreshing'
      } catch (error) {
        // this is actually the case when login is happening
        console.log(error)
        const token = user ? await user.getIdToken(true) : null;
        commit("setToken", token)
        commit("setUid", user ? user.uid : null)
        // console.log(token)
        commit("setLoaded", authLoaded)
      }
      return state.token
    },
  }
}