<template>
  <b-card no-body v-if="game.content">
    <div class="pointer game-img" @click="goToGame">
      <b-card-img
        v-show="screenshotLoaded"
        :src="screenshot"
        :alt="`${game.content.name} képernyőkép`"
        @load="screenshotLoaded = true"
      />
      <vue-aspect-ratio
        v-show="!screenshotLoaded"
        ar="1440:800"
        class="placeholder"
        :style="{
          'background-image': `url(${placeholder})`,
          overflow: 'hidden'
        }"
      >
        <!--b-card-img
          v-if="cardImage"
          :src="cardImage"
          :alt="`${game.content.name} képernyőkép`"
        /-->
      </vue-aspect-ratio>
    </div>
    <b-card-body>
      <b-card-title>
        <b-link
          class="card-link"
          :to="{
            name: 'ViewGame',
            params: { gamePath: game.content.path }
          }"
        >
          <strong> {{ game.content.name }} </strong>
        </b-link>
      </b-card-title>
      <b-card-text>
        {{ game.content.lead }}
        <div class="mt-2" v-if="game.content.tags">
          <b-tag
            v-for="tag in game.content.tags.slice(0, maxTagCount)"
            :key="tag"
            no-remove
            class="mb-1"
            variant="light"
          >
            #{{ tag }}
          </b-tag>
          <b-tag
            v-if="game.content.tags.length > maxTagCount"
            no-remove
            class="mb-1"
            variant="light"
          >
            +{{ game.content.tags.length - maxTagCount }}
          </b-tag>
        </div>
        <p v-if="game.content.url" class="my-1">
          <b-link class="card-link" target="_blank" :href="game.content.url">
            {{ displayUrl(game.content.url) }}
          </b-link>
        </p>
      </b-card-text>
    </b-card-body>
    <b-card-footer>
      <div class="d-flex align-items-center">
        <star-rating v-if="game.rating" :value="game.rating.average" readonly />
        <b-link
          class="card-link ml-auto"
          :to="{
            name: 'ViewGame',
            params: { gamePath: game.content.path }
          }"
        >
          Tovább
        </b-link>
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import StarRating from "../components/StarRating.vue";
import displayUrl from "@/utils/displayUrl";
import placeholder from "@/assets/image-placeholder.svg?data";

export default {
  name: "GameCard",
  components: { StarRating },
  props: {
    game: {
      required: true
    }
  },
  data: () => ({ screenshotLoaded: false, placeholder, maxTagCount: 3 }),
  computed: {
    screenshot() {
      return `${process.env.VUE_APP_API_URL}games/${this.game.id}/screenshot`;
    },
    cardImage() {
      return (
        (this.game.meta &&
          this.game.meta.find((l) => l.url === this.game.content.url) &&
          this.game.meta.find((l) => l.url === this.game.content.url).image) ||
        ""
      );
    }
  },
  methods: {
    displayUrl,
    async goToGame() {
      await this.$router.push({
        name: "ViewGame",
        params: { gamePath: this.game.content.path }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.game-img {
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
</style>
