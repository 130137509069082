<template>
  <div class="da">
    <div class="wrapper">
      <div
        v-if="adblocker"
        class="pt-4 px-3 d-flex flex-column align-items-center justify-content-center"
      >
        <h3 class="text-center">
          <strong>
            ez itt a .<span class="text-primary">reklám</span> helye
          </strong>
        </h3>
        <p class="text-center">
          Kérjük, hogy támogass minket az adblockered kikapcsolásával!
        </p>
      </div>
      <component
        :is="c.is"
        data-ad-client="ca-pub-6330463383637873"
        :data-ad-slot="c.slot"
      />
    </div>
    <p
      class="text-center font-weight-light py-1"
      v-b-modal="`ad-modal-${_uid}`"
    >
      <small>
        Miért van itt
        <strong> .<span class="text-primary">reklám</span>?</strong>
      </small>
    </p>
    <b-modal :id="`ad-modal-${_uid}`" hide-header>
      <div class="p-3">
        <h4>
          Miért van
          <strong> .<span class="text-primary">reklám</span></strong> az
          oldalon?
        </h4>
        <p>
          Az oldal üzemeltetése munkánkon felül objektív kiadásokkal is jár
          (hosting szolgáltatás, domain stb.)
        </p>
        <p>
          Szeretnénk, ha ezek a kiadások nem állnák útját a
          <strong>mitjátsszunk.online</strong> működésének.
        </p>
        <p>
          Kérünk, hogy támogass minket azzal, hogy engeded a reklámok
          megjelenítését az oldalon! A későbbiekben tervezünk lehetőséget
          biztosítani támogatóinknak a reklámmentes megjelenítésre. Ha szeretnél
          ebben részt venni, jelezd szándékodat a közösségi média
          elérhetőségeink valamelyikén!
        </p>
        <strong> .<span class="text-primary">köszönjük</span>!</strong>
        <div class="mt-4 d-flex align-items-center justify-content-center">
          <b-button
            variant="link"
            href="https://discord.gg/3d4jd6A4C3"
            target="_blank"
            title="Mit játsszunk online? a Discordon"
          >
            <b-icon-discord color="#7289da" />
          </b-button>
          <b-button
            href="https://instagram.com/mitjatsszunk.online"
            target="_blank"
            variant="link"
            title="Mit játsszunk online? az Instagramon"
          >
            <b-icon-instagram color="#cb007d" />
          </b-button>
          <b-button
            href="https://facebook.com/mitjatsszunk.online"
            target="_blank"
            variant="link"
            title="Mit játsszunk online? a Facebookon"
          >
            <b-icon-facebook color="#1876f2" />
          </b-button>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="primary" @click="hide()">
          Rendben
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "Ad",
  props: {
    type: {
      type: String,
      default: "square"
    }
  },
  data: () => ({ adblocker: false }),
  mounted() {
    this.adblocker = !window.canShowAds;
    window.setTimeout(() => {
      this.adblocker = !window.canShowAds;
    }, 1000);
  },
  computed: {
    c() {
      switch (this.type) {
        case "article":
          return { is: "InArticleAdsense", slot: "6713326740" };
        case "feed":
          return { is: "InFeedAdsense", slot: "7719356468" };
        default:
          return { is: "Adsense", slot: "3804323888" };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.da {
  background-color: #e9ecef;
  border-radius: 0.3rem;

  // .wrapper {
  //   // min-height: 80px;
  //   // background-position: center;
  //   // background-repeat: no-repeat;
  // }
  > p {
    opacity: 0.6;
    transition: all 0.3s;
    cursor: help;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
