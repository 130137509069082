<template>
  <div id="app" class="py-5">
    <nav-bar />
    <router-view class="mt-2" />
    <bottom-bar />
  </div>
</template>

<script>
import BottomBar from "./components/BottomBar.vue";
import NavBar from "./components/NavBar.vue";
export default {
  name: "App",
  components: { NavBar, BottomBar }
};
</script>
