<template>
  <b-navbar
    type="light"
    variant="light"
    fixed="bottom"
    class="d-sm-none"
    :class="{ shadow: !bottomScrolled }"
  >
    <b-navbar-nav class="w-100 justify-content-around">
      <b-nav-item :to="{ name: 'Home' }" :active="$route.name === 'Home'">
        <img src="/favicon.svg" width="20" alt="Főoldal" />
      </b-nav-item>
      <b-nav-item :to="{ name: 'Games' }" :active="$route.name === 'Games'">
        <b-icon-dice5 />
      </b-nav-item>
      <b-nav-item :to="{ name: 'About' }" :active="$route.name === 'About'">
        <b-icon-question-circle />
      </b-nav-item>
      <b-nav-item
        v-if="
          currentUser &&
            (currentUser.role === 'admin' || currentUser.role === 'moderator')
        "
        :to="{ name: 'Admin' }"
        :active="$route.name === 'Admin'"
      >
        <b-icon-shield-lock />
      </b-nav-item>
      <b-nav-item
        v-if="uid"
        :to="{ name: 'Profile' }"
        :active="$route.name === 'Profile'"
      >
        <b-icon-person />
      </b-nav-item>
      <b-button v-else variant="link" v-b-modal.sign-in-modal>
        <b-icon icon="box-arrow-in-right"></b-icon>
      </b-button>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import AuthMixin from "@/mixins/AuthMixin";
import UserModuleMixin from "@/mixins/UserModuleMixin";
export default {
  name: "BottomBar",
  mixins: [AuthMixin, UserModuleMixin],
  data: () => ({ bottomScrolled: false }),
  mounted() {
    window.addEventListener("scroll", () => {
      this.bottomScrolled =
        window.innerHeight + Math.ceil(window.pageYOffset) >=
        document.body.offsetHeight;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.navbar {
  transition: all 0.3s;
  .nav-link.active {
    &::after {
      content: "";
      display: block;
      background-color: $primary;
      border-radius: 5px 5px 0 0;
      height: 5px;
      width: 36px;
      position: absolute;
      margin: 0 -8px;
      bottom: 0;
    }
  }
}
</style>
